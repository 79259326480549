import { Injectable } from '@angular/core';
import { Observable, catchError, interval, map, throwError } from 'rxjs';
import { IPaginationResponse, IRestResponse } from '../core/interface/response.interface';
import { Environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { IUserList, IUserListDetails } from '../interfaces/userlist.interface';
import { ISearch, ISearchQueryParams } from '../core/interface/search.interface';
@Injectable({
  providedIn: 'root'
})
export class UserListService {

  variables: string[] = [
    'client_name',
    'mobile_number',
    'variable1',
    'variable2',
    'variable3',
    'variable4',
    'variable5',
    'variable6',
    'variable7',
    'variable8',
    'variable9',
    'variable10'
  ]
  tableHeading: string[] = [
    'client name',
    'mobile number',
    'variable 1',
    'variable 2',
    'variable 3',
    'variable 4',
    'variable 5',
    'variable 6',
    'variable 7',
    'variable 8',
    'variable 9',
    'variable 10'
  ]
  constructor(private restService: RestService) { }

  public getAllUserList(): Observable<IRestResponse<IUserList[]>> {
    return this.restService
      .get(
        Environment.UrlPath + `/client/list`,
        null,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public getUserList(
    listId: string
  ): Observable<IRestResponse<IUserList>> {
    return this.restService
      .get(
        Environment.UrlPath + `/client/list${listId}`,
        null,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
  public getUserListDetails(
    listId: string
  ): Observable<IRestResponse<IUserListDetails[] | any>> {
    return this.restService
      .get(
        Environment.UrlPath + `/client/list/details/${listId}`,
        null,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
  public downloadEmptyUserList(
    noOfVariables: string
  ): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + `/client/list/download/excel/${noOfVariables}`,
        null,
        true,
        true
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public uploadFilledUserList(
    payload: any
  ): Observable<any> {
    return this.restService
      .post(
        Environment.UrlPath + `/client/list/upload/excel`,
        payload,
        false,
        true
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public searchUserList(payload: ISearchQueryParams): Observable<IRestResponse<IPaginationResponse<IUserList[]>>> {
    return this.restService
      .get(
        Environment.UrlPath + `/client/list/search`,
        payload,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public activateUserList(listId: string): Observable<IRestResponse<IUserList[]>> {
    return this.restService.put(Environment.UrlPath + `/client/list/${listId}/activate`, null, false)
      .pipe(map((res: any) => res),
        catchError(error => throwError(error))
      );
  }

  public deactivateUserList(listId: string): Observable<IRestResponse<IUserList[]>> {
    return this.restService.put(Environment.UrlPath + `/client/list/${listId}/deactivate`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      )
  }

}